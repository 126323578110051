@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.2/font/bootstrap-icons.min.css");

body {
    /*background-color: #FBFBF1;*/
}

.header {
    text-align: center;
    padding: 20px;
}

.logo {
    width: 225px; /* Adjust the size of your logo */
}

.navbar {
    background-color: #FBFBF1;
}


		
    