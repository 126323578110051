h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 700;
  line-height: 1.2;

}
h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

.features-icons {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.FAQs {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.features-icons .features-icons-item {
  max-width: 20rem;
}
.features-icons .features-icons-item .features-icons-icon {
  height: 7rem;
}
.features-icons .features-icons-item .features-icons-icon i {
  font-size: 4.5rem;
}

.features-icons-item-header .text-center {
  font-size: 1rem;
}

.features-icons-item-header-lg{
  height: 6rem;
}

.features-icons .lead {
  font-size: 1rem;
}

.masthead {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: url("../assets/img/bg-upright-masthead.jpeg") no-repeat;
  background-size: cover;
}

/* For mobile devices */
@media (max-width: 767px) {
  .masthead {
    height: 40vh;
  }
}

/* For tablet devices */
@media (min-width: 768px) and (max-width: 991px) {
  .masthead {
    height: 35vh;
  }
}

/* For desktop devices */
@media (min-width: 992px) {
  .masthead {
    background-position: center -8rem; /* Adjust this value to move the background image */
    height: 45vh;
  }
}

.cta {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto 0;
  height: 33.33%;
  color: white;
}

.search-form {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto 0;
  height: 77.77%;

}

.search-form > * {
  flex-grow: 1;
}

@media (max-width: 991px) {
  .search-form {
  }
}

@media (min-width: 992px) {
  .search-form {
    width: 65%;
  }
}

.search-form .submit-button {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}



footer.footer {
  padding-top: 4rem;
  padding-bottom: 0rem;
}

.uselocationbutton {
  background-color: white;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.uselocationbutton:hover, .uselocationbutton:active {
  background-color: grey; /* or any other color */
}

